<template>
    <div class="pages-wrapper">
        <div
                class="left"
        >
            <button
                    @click.stop.prevent="toFirstPage"
                    class="page pageArrow"
                    :disabled="currentPage === 1"
            >
                <double-angles/>
            </button>
            <button
                    @click.stop.prevent="prevPage"
                    class="page pageArrow"
                    :disabled="currentPage === 1"
            >
                <single-angle/>
            </button>
        </div>
        <div class="numbers-wrapper">
            <button
                    v-for="page in displayedPages"
                    :key="page"
                    class="page pageArrow"
                    :class="{
                      current: page === currentPage
                    }"
                    @click.stop.prevent="selectPage(page)"
            >{{page}}</button>
        </div>
        <div
                class="right"
        >
            <button
                    @click.stop.prevent="nextPage"
                    class="page pageArrow"
                    :disabled="currentPage === totalPageCount"
            >
                <single-angle/>
            </button>
            <button
                    @click.stop.prevent="toLastPage"
                    class="page pageArrow"
                    :disabled="currentPage === totalPageCount"
            >
                <double-angles/>
            </button>
        </div>
    </div>
</template>

<script>
  import DoubleAngles from "../../../assets/img/doubleAngles";
  import SingleAngle from "../../../assets/img/singleAngle";
  export default {
    name: "PaginationComponent",
    components: {SingleAngle, DoubleAngles},
    props: {
      totalElements: {
        type: Number,
        required: true,
      },
      elementsPerPage: {
        type: Number,
        required: true,
      },
      maxDisplayPage: {
        type: Number,
        default: 5
      }
    },
    data() {
      return {
        currentPage: 1,
        totalPageCount: Math.ceil(this.totalElements / this.elementsPerPage)
      }
    },
    watch: {
      currentPage(newPage){
        this.$emit('page-changed', newPage)
      }
    },
    computed: {
      firstDisplayPageNumber() {
        const end = this.totalPageCount - this.maxDisplayPage + 1;
        const middle = this.currentPage - Math.floor(this.maxDisplayPage / 2)
        const calc = Math.min(end, middle)
        return Math.max(1, calc )
      },
      lastDisplayPageNumber(){
        return Math.min(this.totalPageCount, this.firstDisplayPageNumber + (this.maxDisplayPage - 1))
      },
      displayedPages(){
        let pages = [];
        for (let i = this.firstDisplayPageNumber; i <= this.lastDisplayPageNumber; i++){
          pages.push(i)
        }
        return pages;
      }
    },
    methods: {
      toFirstPage(){
        this.currentPage = 1;
      },
      toLastPage(){
        this.currentPage = this.totalPageCount;
      },
      nextPage(){
        this.currentPage = this.currentPage + 1
      },
      prevPage(){
        this.currentPage = this.currentPage - 1;
      },
      selectPage(page){
        this.currentPage = page
      }
    }
  }
</script>

<style scoped lang="scss">
    @import "../../../assets/css/colors";

    .pages-wrapper{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .numbers-wrapper{
        display: flex;
        flex-wrap: nowrap;
        gap: 1rem;
        color: $text-tertiary-middle;
    }
    .page{
        outline: none;
        background: transparent;
        border: none;
        border-radius: .5rem;
        &:hover{
            background: rgba($text-tertiary-light-rgb,.15);
        }
        &:disabled{
            opacity: .6;
            cursor: auto;
        }
    }
    .pageArrow{
        width: 2.5rem;
        height: 2.5rem;
        padding: .5rem;
    }
    .left,
    .right{
        display: flex;
        gap: .5rem;
        svg{
            width: 100%;
            height: 100%;
        }
    }
    .right{
        svg{
            transform: rotate(180deg);
        }
    }
    .current{
        font-weight: bold;
        position: relative;
        &:hover{
            background: transparent;
        }
        &:after{
            content: "";
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            border-radius: 2px;
            background: rgba($text-tertiary-light-rgb,.5);
        }
    }
</style>